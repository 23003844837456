const menu_data = [{
        question: "Szeretnék igényelni Tízórai kártyát, hol tudom ezt megtenni?",
        answer: "Az iskola titkárságán teheted meg mindezt, gyermekednek csak kérnie kell és kap egy kártyát ingyenesen.",
    },
    {
        question: "A kártya megvan, hogyan tovább?",
        answer: 'Az első lépés a mobilapplikáció letöltése a Google Play vagy az App Store áruház egyikéből. Az együttműködés menüpont alatt, a Szülők részére gombra kattintva minden információt megtalálhatsz. Az iskolában szórólap formájában is kiküldésre került a tájékoztató, illetve ide is feltöltésre került.',
    },
    {
        question: "Szeretném, ha gyermekem iskolájában is elérhető lenne a Tízórai.",
        answer: "Kérjük, vedd fel a kapcsolatot velünk az info@tizorai.com e-mail címen és igyekszünk elintézni, hogy mihamarabb jelen legyünk az érintett iskolában.",
    },
    {
        question: "Mi történik, ha elvesztette gyermekem a kártyáját?",
        answer: "A mobilapplikációban le tudod tiltani az elveszett kártyát. Jelentkezz be, kattint a beállítások menüpontra, majd a Kártya letiltására. Ha letiltod, az egyenleg nincs veszélyben, más nem fér hozzá a használathoz. Igényeljetek új kártyát a titkárságon, majd vegyétek fel velünk a kapcsolatot az info@tizorai.com e-mail címen",
    },
    {
        question: "Nem adtam meg a Közlemény rovatban a Tízórai kártya számát.",
        answer: "Kérjük, minél hamarabb vedd fel velünk a kapcsolatot az info@tizorai.com e-mail címen, hogy az egyenleg minél hamarabb gyermeked kártyájára kerülhessen.",
    },
    {
        question: "Gyermekem allergiás a diófélékre, tudom szabályozni, mihez fér hozzá?",
        answer: "Természetesen! A mobilapplikációban és a weblapon is megtalálod az általunk árusított termékek listáját. Ha rákattintasz adott termékre, láthatod összetevőit és az esetleges allergéneket. Jelentkezz be, kattints a beállítások menüpontra, majd a Szabályozásokra. Alul láthatod, mely jellemzővel rendelkeznek bizonyos termékek. Amelyikre allergiás gyermeked, kattintással letilthatod.",
    },
    {
        question: "Hogyan tudok pénzt feltölteni a Tízórai kártyára?",
        answer: "Két opció áll rendelkezésre: A fizetési kapun keresztül kártyás fizetéssel, vagy átutalással bankszámláról.",
    },

    {
        question: "Van-e bármilyen extra költsége a Tízórai kártyának?",
        answer: "A kártya aktivációs illetéke 200 Ft, mely a legelső feltöltéskor automatikusan levonásra kerül. Ezután, amennyiben gyermeked nem veszti el kártyáját, semmilyen további költség nem terheli azt a termékek ellenértékén kívül.",
    },
];

export default menu_data;
import React from "react";
import { Link } from "react-router-dom";

import shape from "src/styles/img/citrom.png";
import shape_download from "src/styles/img/download.svg";

const Products1 = () => {
  return (
    <>
      <section className="slider__area pt-180 z-index-1 p-relative fix">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-3">
              <img className="img-fluid pr-40" src={shape} alt="shape" />
            </div>
            <div className="col-lg-9">
              <div className="d-flex justify-content-center flex-column">
                <h1 className="">
                  Egyszerre finom és egészséges?
                  <br /> Velünk megoldható!
                </h1>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <p className="mt-10 mb-10">
                      Tekintse át termékkínálatunkat, melyet kifejezetten <br />
                      gyermekek számára fejlesztettek ki partnereink!{" "}
                      <strong>
                        {" "}
                        Aktuális árlistánkat a letöltés gombra kattintva
                        tekintheti meg.{" "}
                      </strong>
                    </p>
                  </div>
                  <div className="col">
                    <Link
                      to="/arlista.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img className="" src={shape_download} alt="letöltés" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products1;

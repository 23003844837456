import React from "react";
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import SEO from "src/components/seo";
import { Helmet } from "react-helmet-async";

const GDPR1 = () => {
  return (
    <div id="1">
      <div className="aszf__section">
        <p>
          Uzsi4Kids Nonprofit Kft., székhelye 1063 Budapest, Szondi utca 70.,
          adószáma 32189117-2-42, cégjegyzékszáma 01 09 411117, bejegyezve
          2023.01.19-én a Fővárosi Törvényszék Cégbíróságának nyilvántartásában,
          a továbbiakban, mint a személyes adatok kezelője, illetve Ön, mint a
          <Link to="/">www.tizorai.com</Link>
          weboldal és a Tízórai mobilalkalmazás felhasználója tájékoztatjuk a
          személyes adatainak gyűjtéséről és az alábbiakban ismertetett
          adatvédelmi elvekről.
        </p>
        <p>
          Amennyiben a továbbiakban az ismertető bármely részét szeretné, ha
          elmagyaráznánk, vagy tanácsot kérne, vagy személyes adatai további
          kezeléséről szeretne informálódni, vegye fel velünk a kapcsolatot az
          info@tizorai.com e-mail címen keresztül.
        </p>
      </div>
    </div>
  );
};

const GDPR2 = () => {
  return (
    <div id="2">
      <h1>A személyes adatok kezelésének köre</h1>
      <div className="aszf__section">
        <p>
          A Tízórai projekt (egészséges snackek, ételek és italok a diákok
          számára) megvalósítása során a következő személyes adatok gyűjthetők:
        </p>

        <ul className="ml-20">
          <li>az Ön kereszt- és vezetékneve,</li>
          <li>az iskola neve,</li>
          <li>az iskola címe vagy székhelye,</li>
          <li>azonosító szám és adóazonosító jel,</li>
          <li>telefonszám</li>
          <li>e-mail cím</li>
        </ul>
      </div>
    </div>
  );
};

const GDPR3 = () => {
  return (
    <div id="3">
      <h1>A személyes adatok gyűjtésének és feldolgozásának célja</h1>
      <div className="aszf__section">
        <p>
          Az Ön által megadott adatokat arra használjuk, hogy visszakereshessük
          Önt és megadjuk az Ön által kért információkat, illetve
          kötelezettségeink teljesítése érdekében a Tízórai projekt keretén
          belül. A www.tizorai.com weboldalon csak a Google Analytics
          mérőszámait használjuk, amit marketing célokra használunk.
        </p>
        <p>
          Valamennyi személyes adat kezelése törvényes és átlátható módon
          történik, és csak az adatkezelés céljával összefüggésben megfelelő,
          releváns és szükséges adatokra van szükség. Az Ön nevét, vezetéknevét
          és e-mail címét arra használhatjuk, hogy kommunikálhassunk Önnel, azaz
          tájékoztatást nyújtsunk az általunk nyújtott eseményekről, újításokról
          vagy szolgáltatásokról, amelyek véleményünk szerint érdekelhetik Önt.
        </p>
        <p>
          A szerződés teljesítése érdekében a személyes adatok megadása,
          valamint az Ön által feltett kérdések megválaszolása vagy az Ön által
          kért információk megválaszolása céljából történő személyes adatok
          megadása szerződéses követelményeink, amelyek elmulasztása
          szerződéskötés meghiúsulását vonhatja maga után.
        </p>
        <p>
          Ezen túlmenően Ön bármikor megtagadhatja személyes adatainak
          marketingüzenetek küldése céljából történő feldolgozását. Csak el kell
          küldenie nekünk kérését az info@tizorai.com email címre vagy más olyan
          címre, amelyről efféle üzeneteket kapott tőlünk, vagy módosítania kell
          a Tízórai kártyák beállításait a Fiókom részben való bejelentkezéssel
          a Tízórai mobilalkalmazásban.
        </p>
      </div>
    </div>
  );
};
const GDPR4 = () => {
  return (
    <div id="4">
      <h1>Ki fér hozzá az Ön személyes adataihoz?</h1>
      <div className="aszf__section">
        <p>
          Mi, mint rendszergazda kezeljük az Ön személyes adatait. Rajtunk kívül
          kizárólag szerver-, web-, felhő- vagy informatikai szolgáltatásokat
          nyújtó vállalkozások láthatják az adatokat, akik számunkra végeznek
          feladatokat. A személyes adatokat az EU-n és az EGT-n kívüli
          országokból származó harmadik feleknek nem adjuk át.
        </p>
      </div>
    </div>
  );
};

const GDPR5 = () => {
  return (
    <div id="5">
      <h1>A személyes adatok kezelésének ideje</h1>
      <div className="aszf__section">
        <p>
          Személyes adatait arra az időtartamra kezeljük, ameddig
          szolgáltatásainkat nyújtjuk, vagy kölcsönös megállapodást teljesítünk,
          illetve a vonatkozó jogszabályok, így a számviteli törvény, illetve a
          hatályos törvények szerint az archiválási kötelezettségek
          teljesítéséhez szükséges ideig.
        </p>
      </div>
    </div>
  );
};

const GDPR6 = () => {
  return (
    <div id="6">
      <h1>A személyes adatok kezeléséből eredő jogai</h1>
      <div className="aszf__section">
        <p>
          Személyes adatainak feldolgozásával kapcsolatban Önt az alábbi jogok
          illetik meg:
        </p>
        <ul className="ml-20">
          <li>a személyes adatokhoz való hozzáférés joga;</li>
          <li>a helyesbítéshez való jog;</li>
          <li>a törléshez való jog („az elfelejtéshez való jog”);</li>
          <li>az adatkezelés korlátozásának joga;</li>
          <li>az adatkezelés elleni tiltakozás joga;</li>
          <li>
            a személyes adatok kezelésével kapcsolatos panasz benyújtásának
            joga.
          </li>
        </ul>
        <p>
          Az Ön jogait tovább magyarázzuk, hogy világosabb képet kapjon azok
          tartalmáról.
        </p>
        <p>
          A hozzáférési jog azt jelenti, hogy bármikor kérheti megerősítésünket,
          hogy Önre vonatkozó személyes adatok kezelése folyamatban van-e, és ha
          igen, akkor milyen célból, milyen mértékben, kinek adjuk
          hozzáférhetővé, mennyi ideig fogja kezelni, függetlenül attól, hogy
          jogosult-e a helyesbítésre, törlésre, az adatkezelés korlátozására
          vagy tiltakozásra, honnan szereztük be a személyes adatokat, és hogy
          az Ön személyes adatainak kezelése alapján történik-e automatikus
          döntéshozatal, beleértve az esetleges profilalkotást is. Ön jogosult
          arra is, hogy személyes adatairól másolatot kapjon, míg az első
          rendelkezés ingyenes, a további adatszolgáltatásért a Közvetítő
          méltányos adminisztrációs költségek megfizetését követelheti.
        </p>
        <p>
          A helyesbítéshez való jog azt jelenti, hogy bármikor kérheti tőlünk
          személyes adatainak helyesbítését vagy kiegészítését, ha azok
          pontatlanok vagy hiányosak. A törléshez való jog azt jelenti, hogy
          törölnünk kell az Ön személyes adatait, ha (I) azokra már nincs
          szükség abból a célból, amelyből azokat gyűjtötték vagy más módon
          kezelték, (II) az adatkezelés jogellenes, (III) Ön tiltakozik az
          adatkezelés ellen és a feldolgozásnak nincsenek nyomós jogos okai,
          vagy (IV) erre törvény kötelez bennünket.
        </p>
        <p>
          Az adatkezelés korlátozásához való jog azt jelenti, hogy amíg az Ön
          személyes adatai kezelésével kapcsolatos vitás kérdéseket meg nem
          oldjuk, addig korlátoznunk kell az Ön személyes adatainak kezelését
          úgy, hogy azokat csak tárolni tudjuk, és esetlegesen felhasználhassuk
          azok meghatározására, gyakorlására, vagy jogi igények védelmében.
        </p>
        <p>
          A tiltakozáshoz való jog azt jelenti, hogy tiltakozhat azon személyes
          adatainak kezelése ellen, amelyeket direkt marketing célból vagy egyéb
          érdekből kezelünk. Ha tiltakozik a direkt marketing célú adatkezelés
          ellen, személyes adatait a továbbiakban nem kezeljük e célból.
        </p>
        <p>
          Amennyiben szeretne a fentiek szerinti jogaival élni, írjon e-mailt az
          info@tizorai.com e-mail címre.
        </p>
        <p>Kérjük, levelében tüntesse fel:</p>
        <ul className="ml-20">
          <li>Azonosító adatok – név, vezetéknév, születési idő</li>
          <li>
            Az Ön által kért jogok gyakorlására vonatkozóan lásd a személyes
            adatok alanya jogainak leírását
          </li>
          <li>
            A kérelem pontosítása – pl. helyesbítés esetén az adatok
            helyesbítése
          </li>
          <li>
            Telefonszám – az esetleges pontosításokért és a következő eljárásról
            való egyeztetésért
          </li>
        </ul>
        <p>
          Abban az esetben, ha kétségek merülnek fel az Ön személyazonosságával
          kapcsolatban, jogosultak vagyunk további adatok megadását kérni
          személyazonosságának megerősítéséhez.
          <br />
          Az érintettek jogainak gyakorlásával kapcsolatos cselekmények
          térítésmentesen valósulnak meg.
          <br />
          Jelen Adatvédelmi szabályzat 2023.03.01.-től hatályos.
        </p>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <section className="slider__area pt-160 mb-50 z-index-1 aszf">
      <div className="container">
        <div className="row">
          <h1 className="text-center gdpr__h1">GDPR</h1>
          <div className="col-3 d-none d-sm-block">
            <div className="sticky-top gdpr__nav">
              <div className="nav flex-column">
                <HashLink to="#1">I. Bevezetés</HashLink>
                <HashLink to="#2">
                  II. A személyes adatok kezelésének köre
                </HashLink>
                <HashLink to="#3">
                  III. Ki fér hozzá az Ön személyes adataihoz?
                </HashLink>
                <HashLink to="#4">
                  IV. A személyes adatok kezelésének ideje
                </HashLink>
                <HashLink to="#5">
                  V. A személyes adatok kezeléséből eredő jogai
                </HashLink>
              </div>
            </div>
          </div>
          <div className="col">
            <GDPR1 />
            <GDPR2 />
            <GDPR3 />
            <GDPR4 />
            <GDPR5 />
            <GDPR6 />
          </div>
        </div>
      </div>
    </section>
  );
};

const GDPR = () => {
  return (
    <>
      <Helmet>
        <title>GDPR</title>
        <meta
          name="description"
          content="Általános adatvédelmi rendelet (GDPR) szövege a Tízórai automatával kapcsolatban"
        />
        <link rel="canonical" href="/gdpr" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Content />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default GDPR;

const menu_data = [
  {
    id: 1,
    hasDropdown: false,
    title: "rólunk",
    link: "/",
  },
  {
    id: 2,
    hasDropdown: false,
    title: "termékek",
    link: "/termekek",
  },
  {
    id: 3,
    hasDropdown: true,
    title: "együttműködés",
    link: "",
    submenus: [
      { title: "Iskolák részére", link: "/iskolak-reszere" },
      { title: "Szülők részére", link: "/szulok-reszere" },
      { title: "Vállalatok részére", link: "/vallalatok-reszere" },
    ],
  },
  {
    id: 4,
    hasDropdown: false,
    title: "kapcsolat",
    link: "/kapcsolat",
  },
  {
    id: 5,
    hasDropdown: false,
    title: "kérdések",
    link: "/gyik",
  },
];

export default menu_data;

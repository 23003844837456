/*
 * Név
 * Leírás
 * url
 * Ár
 * Összetétel
 * Alergének
 * Tápértékek
 * /img//products/product3.svg
 */

const menu_data = [
  {
    name: "Sió vitatigris gyümölcspüré banános",
    content: [
      "almapüré 50%",
      " szőlőlé 32% (sűrítményből)",
      " banánpüré 18%",
      "vitaminok: C, D",
    ],
    nutrition: [
      "Energia: 267 kJ/62 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 14,6 g, amelyből cukrok: 13,9 g",
      "Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "90 g",
    allergens: ["allergénmentes"],
    src: "/img/products/34. Sio vitatigris gyumolcspure bananos.jpg",
  },
  {
    name: "Sió vitatigris gyümölcspüré barackos",
    content: [
      "almapüré 36%",
      " őszibarackpüré 23%",
      " szőlőlé 21%",
      " kajszibarackpüré 10%",
      " banánpüré 9%",
      "körtepüré 1%",
      "vitaminok: C, D",
    ],
    nutrition: [
      "Energia: 252 kJ/59 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 13,1 g, amelyből cukrok: 12,4 g",
      " Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "90 g",
    allergens: ["allergénmentes"],
    src: "/img/products/35. Sio vitatigris gyumolcspure barackos.jpg",
  },
  {
    name: "Sió vitatigris gyümölcspüré epres",
    content: [
      "almapüré 72%",
      " szőlőlé 18%",
      " eperpüré 9%",
      " feketerépalé",
      " vitaminok: C, D",
    ],
    nutrition: [
      "Energia: 256 kJ/60 kcal",
      "Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 14,2 g, amelyből cukrok: 13,8 g",
      "Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "90 g",
    allergens: ["allergénmentes"],
    src: "/img/products/36. Sio vitatigris gyumolcspure epres.jpg",
  },
  {
    name: "Sió vitatigris gyümölcspüré körtés",
    content: ["almapüré 63%, körtepüré 22 %, szőlőlé15%, vitaminok: C, D"],
    nutrition: [
      "Energia: 247 kJ/58 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 13,8 g, amelyből cukrok: 13,1 g",
      "Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "90 g",
    allergens: ["allergénmentes"],
    src: "/img/products/37. Sio vitatigris gyumolcspure kortes.jpg",
  },
  {
    name: "Sió vitatigris gyümölcspüré mangós",
    content: [
      "almavelő 63,4%",
      " banán velő 16,1%",
      " almalé 8,7% (sűrítményből)",
      "mangóvelő 5,7%",
      "maracujalé 4,5%",
      "ananász velő 1,6%",
      "vitaminok: C, D",
    ],
    nutrition: [
      "Energia: 234 kJ/55 kcal",
      "Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      "Szénhidrát: 12,6 g, amelyből cukrok: 11,8 g",
      "Fehérje: 0,5 g",
      "Só: 0,01 g",
    ],
    amount: "90 g",
    allergens: ["allergénmentes"],
    src: "/img/products/38. Sio vitatigris gyumolcspure mangos.jpg",
  },

  {
    name: "Sió gyümölcslé natura 100% alma",
    content: ["szűrt almalé", " almalésűrítményből"],
    nutrition: [
      "Energia: 183 kJ/43 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 10,2 g, amelyből cukrok: 9,7 g",
      "Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "200 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/33. Sio gyumolcsle natura 100 alma.jpg",
  },

  {
    name: "HohesC gyümölcslé multivitamin",
    content: [
      "vegyes gyümölcslé gyümölcslé sűrítményekből (almalé, narancslé, ananászlé, maracujalé, acerolalé, citromlé)",
      "banán-, mangó-, nektarinvelő, niacin, pantoténsav, folsav, biotin",
      "vitaminok: E, A, B6, B2, B1, B12",
    ],
    nutrition: [
      "Energia: 189 kJ/44 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 9,9 g, amelyből cukrok: 9,5 g",
      " Rost: 0,5",
      " Fehérje: 0,5 g",
      " Só: 0,01 g",
    ],
    amount: "200 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/20. HohesC gyumolcsle multivitamin.jpg",
  },
  {
    name: "HohesC gyümölcslé narancs",
    content: ["narancslé narancslésűrítményből"],
    nutrition: [
      "Energia: 183 kJ/43 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 8,8 g, amelyből cukrok: 8,8 g",
      " Rost: 0,5 g",
      " Fehérje: 0,7 g",
      " Só: 0,01 g",
    ],
    amount: "200 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/21. HohesC gyumolcsle narancs.jpg",
  },

  // TODO

  {
    name: "Csinta almás gyümölcstekercs",
    content: ["alma"],
    nutrition: [
      "Energia: 1338 kJ/316 kcal",
      "Zsír: 0,7 g, amelyből telített zsírsavak: 0 g;",
      "Szénhidrát: 68,3 g, amelyből cukrok: 68,3 g;",
      "Fehérje: 2,5 g;",
      "Só: 0,05 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/3. Csinta almas gyumolcstekercs.jpg",
  },
  {
    name: "Csinta birsalmás gyümölcstekercs",
    content: ["alma", "birsalma"],
    nutrition: [
      "Energia: 1340 kJ/317 kcal",
      "Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      "Szénhidrát: 67 g, amelyből cukrok: 66,8 g",
      "Fehérje: 3 g",
      "Só: 0,08 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img//products/4. Csinta birsalmas gyumolcstekercs.jpg",
  },
  {
    name: "Csinta epres gyümölcstekercs",
    content: ["alma", "eper"],
    nutrition: [
      "Energia: 1375 kJ/325 kcal",
      "Zsír: 0,4 g, amelyből telített zsírsavak: 0 g",
      "Szénhidrát: 68,2 g, amelyből cukrok: 67,5 g",
      "Fehérje: 4,7 g",
      "Só: 0,09 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img//products/5. Csinta epres gyumolcstekercs.jpg",
  },
  {
    name: "Csinta gyömbéres gyümölcstekercs",
    content: [
      "alma",
      "gyömbér",
      " frissen facsart citromlé",
      " frissen őrölt fűszerek",
    ],
    nutrition: [
      "Energia: 1334 kJ/315 kcal",
      "Zsír: 0,7 g, amelyből telített zsírsavak: 0 g",
      "Szénhidrát: 67,8 g, amelyből cukrok: 66,4 g",
      "Fehérje: 2,5 g",
      "Só: 0,05 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img//products/6. Csinta gyomberes gyumolcstekercs.jpg",
  },
  {
    name: "Csinta homoktövises gyümölcstekercs",
    content: ["alma", "homoktövis"],
    nutrition: [
      "Energia: 1346 kJ/318 kcal",
      "Zsír: 2,3 g, amelyből telített zsírsavak: 0,8 g",
      "Szénhidrát: 67,9 g, amelyből cukrok: 63,8 g",
      "Fehérje: 1,8 g",
      "Só: 0 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/7. Csinta homoktovises gyumolcstekercs.jpg",
  },
  {
    name: "Csinta körtés gyümölcstekercs",
    content: ["alma", " körte"],
    nutrition: [
      "Energia: 1323 kJ/313 kcal",
      "Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      "Szénhidrát: 67,4 g, amelyből cukrok: 67,4 g",
      " Fehérje: 2,3 g",
      "Só: 0,04 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/8. Csinta kortes gyumolcstekercs.jpg",
  },
  {
    name: "Csinta meggyes gyümölcstekercs",
    content: ["alma", " meggy"],
    nutrition: [
      "Energia: 1378 kJ/325 kcal",
      " Zsír: 0,3 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 70,8 g, amelyből cukrok: 70,8 g",
      " Fehérje: 3 g",
      " Só: 0,06 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/9. Csinta meggyes gyumolcstekercs.jpg",
  },
  {
    name: "Csinta őszibarackos gyümölcstekercs",
    content: ["alma", " őszibarack"],
    nutrition: [
      "Energia: 1412 kJ/334 kcal",
      " Zsír: 0,7 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 67,7 g, amelyből cukrok: 64,4 g",
      "Fehérje: 4 g",
      " Só: 0 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/10. Csinta oszibarackos gyumolcstekercs.jpg",
  },
  {
    name: "Csinta sárgabarackos gyümölcstekercs",
    content: ["alma", " sárgabarack"],
    nutrition: [
      "Energia: 1377 kJ/325 kcal",
      " Zsír: 0,5 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 69,4 g, amelyből cukrok: 68,4 g",
      " Fehérje: 5,2 g",
      " Só: 0,09 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/11. Csinta sargabarackos gyumolcstekercs.jpg",
  },
  {
    name: "Csinta szilvás gyümölcstekercs",
    content: ["szilva"],
    nutrition: [
      "Energia: 1401 kJ/330 kcal",
      " Zsír: 0,4 g, amelyből telített zsírsavak: 0 g",
      " Szénhidrát: 74,8 g, amelyből cukrok: 74,2 g",
      " Fehérje: 4 g",
      " Só: 0,06 g",
    ],
    amount: "12 g",
    allergens: ["allergénmentes"],
    src: "/img/products/12. Csinta szilvas gyumolcstekercs.jpg",
  },

  {
    name: "Rajo Brejky epres tej",
    content: [
      "tej 95%",
      " cukor",
      " eper ízű ízesítő 1,6% (kukoricadara, cukor, szárított eper összetevő 12,5% (maltodextrin, szárított eper 40%)",
      " természetes aroma",
      " színezék: karotinok)",
      " vitaminok: A, E, D.",
    ],
    nutrition: [
      "Energia: 280 kJ/66 kcal",
      " Zsír: 1,5 g, amelyből telített zsírsavak: 0,9 g",
      " Szénhidrát: 9,8 g, amelyből cukrok: 8,9 g",
      " Fehérje: 3,4 g",
      " Só: 0,15 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok"],
    src: "/img/products/27. Rajo Brejky epres tej.jpg",
  },
  {
    name: "Rajo Brejky kakaós tej",
    content: [
      "tej 95%",
      " cukor",
      " kakaó ízű ízesítő 1,5% (zsírtalanított kakaópor 69%, cukor, stabilizátor: karragén; természetes aroma)",
      " vitaminok: A, E, D.",
    ],
    nutrition: [
      "Energia: 278 kJ/66 kcal",
      "Zsír: 1,6 g, amelyből telített zsírsavak: 1,0 g",
      " Szénhidrát: 9,1 g, amelyből cukrok: 9,0 g",
      " Fehérje: 3,6 g",
      " Só: 0,15 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok"],
    src: "/img/products/28. Rajo Brejky kakaos tej.jpg",
  },
  {
    name: "Rajo Brejky karamellás tej",
    content: [
      "tej 95%",
      " cukor",
      " karamell ízű ízesítő 1,4% (kukoricadara, karamell szirup 28,6%, cukor, természetes aroma (tej), étkezési só)",
      " vitaminok: A, E, D.",
    ],
    nutrition: [
      "Energia: 282 kJ/67 kcal",
      " Zsír: 1,5 g, amelyből telített zsírsavak: 0,9 g",
      " Szénhidrát: 9,9 g, amelyből cukrok: 9,0 g",
      "Fehérje: 3,4 g",
      " Só: 0,21 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok", " tejkaramella"],
    src: "/img//products/29. Rajo Brejky karamellas tej.jpg",
  },
  {
    name: "Rajo Brejky laktózmentes tej",
    content: ["tej", " laktáz enzim"],
    nutrition: [
      "Energia: 267 kJ/64 kcal",
      "Zsír: 3,5 g, amelyből telített zsírsavak: 2,1 g",
      " Szénhidrát: 4,8 g, amelyből cukrok: 4,8 g",
      " Fehérje: 3,3 g",
      " Só: 0,13 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok"],
    src: "/img/products/30. Rajo Brejky laktozmentes tej.jpg",
  },
  {
    name: "Rajo Brejky tartós tej",
    content: ["tej"],
    nutrition: [
      "Energia: 267 kJ/64 kcal",
      " Zsír: 3,5 g, amelyből telített zsírsavak: 2,1 g",
      " Szénhidrát: 4,8 g, amelyből cukrok: 4,8 g",
      " Fehérje: 3,3 g",
      " Só: 0,13 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok"],
    src: "/img/products/31. Rajo Brejky tartos tej.jpg",
  },
  {
    name: "Rajo Brejky vaníliás tej",
    content: [
      "tej 95%",
      " cukor",
      " vanília ízű ízesítő 1,8% (cukor, kukoricadara, természetes aroma (tej)",
      " színezék: karotinok, Bourbon vanília kivonat 0,3%)",
      " vitaminok: A, E, D.",
    ],
    nutrition: [
      "Energia: 278 kJ/66 kcal",
      " Zsír: 1,5 g, amelyből telített zsírsavak: 0,9 g",
      " Szénhidrát: 9,7 g, amelyből cukrok: 9,0 g",
      " Fehérje: 3,3 g",
      " Só: 0,15 g",
    ],
    amount: "250 ml",
    allergens: ["tej", " tejszármazékok"],
    src: "/img/products/32. Rajo Brejky vanilias tej.jpg",
  },

  {
    name: "VOS CORNie Paradicsom",
    content: [
      "extrudált kukorica",
      "egész gabona",
      " szárított paradicsom 10%",
      " növényi olaj",
      "kukoricacsíra 7%",
      " pirospaprika 1%",
      " tengeri só 0,97%",
    ],
    nutrition: [
      "Energia: 1598 kJ/382 kcal",
      " Zsír: 14,61 g, amelyből telített zsírsavak: 1,38 g",
      " Szénhidrát: 61,55 g, amelyből cukrok: 3,4 g",
      "Fehérje: 8,16 g",
      "Só: 0,93 g",
    ],
    amount: "30 g",
    allergens: ["glutént tartalmazó gabonafélék"],
    src: "/img//products/40. VOS CORNie Paradicsom.jpg",
  },
  {
    name: "VOS ONION Burgonyaszálak",
    content: [
      "burgonya őrlemény",
      " burgonyapehely",
      "burgonya keményítő",
      "növényi olaj",
      "szárított hagyma 8%",
      "tengeri só 0,98%",
      "béta-karotin.",
    ],
    nutrition: [
      "Energia: 1796 kJ/429 kcal",
      "Zsír: 15,39 g, amelyből telített zsírsavak: 1,78 g",
      " Szénhidrát: 65,83 g, amelyből cukrok: 2,36 g",
      "Fehérje: 3,9 g",
      " Só: 0,98 g",
    ],
    amount: "20 g",
    allergens: ["allergénmentes"],
    src: "/img//products/42. VOS ONION Burgonyaszalak.jpg",
  },
  {
    name: "VOS Super Balls",
    content: [
      "kukoricadara",
      " szárított zöldségek keveréke (hagyma, paradicsom, kurkuma, paprika, cékla) 10%",
      " növényi olaj (hidegen sajtolt)",
      " tengeri só 0,9%.",
    ],
    nutrition: [
      "Energia: 1695 kJ/405 kcal",
      " Zsír: 18,65 g, amelyből telített zsírsavak: 1,63 g",
      "Szénhidrát: 58,57 g, amelyből cukrok: 2,91 g",
      " Fehérje: 7,68 g",
      " Só: 0,98 g",
    ],
    amount: "20 g",
    allergens: ["allergénmentes"],
    src: "/img//products/43. VOS Super Balls.jpg",
  },
  {
    name: "VOS TOMATO Burgonyaszálak",
    content: [
      "burgonya őrlemény",
      " burgonyapehely",
      " burgonya keményítő",
      " növényi olaj",
      "szárított paradicsom 4%",
      "szárított hagyma 4%",
      "tengeri só 0,98%",
      "béta-karotin.",
    ],
    nutrition: [
      "Energia: 1778 kJ/425 kcal",
      " Zsír: 15,17 g, amelyből telített zsírsavak: 1,76 g",
      " Szénhidrát: 64,10 g, amelyből cukrok: 5,92 g",
      "Fehérje: 4,35 g",
      "Só: 0,98 g",
    ],
    amount: "20 g",
    allergens: ["allergénmentes"],
    src: "/img/products/44. VOS TOMATO Burgonyaszalak.jpg",
  },

  {
    name: "Nobilis hullámos szAlmaszál",
    content: ["alma", " antioxidáns (citromsav)"],
    nutrition: [
      "Energia: 1405 kJ/332 kcal",
      " Zsír: 0,6 g, amelyből telített zsírsavak: 0,3 g",
      " Szénhidrát: 73 g, amelyből cukrok: 69 g",
      " Rost: 14 g",
      " Fehérje: 1,9 g",
      "Só: 0,13 g",
    ],
    amount: "15 g",
    allergens: ["diófélék"],
    src: "/img//products/24. Nobilis hullamos szAlmaszal.jpg",
  },

  {
    name: "Nobilis tejcsokoládés szAlmaszál",
    content: [
      "tejcsokoládé (min. 60%) (cukor, kakaóvaj",
      " zsíros tejpor",
      " kakaómassza",
      " emulgálószer: szójalecitin, természetes vanília aroma), alma, antioxidáns (citromsav)",
    ],
    nutrition: [
      "Energia: 2014 kJ/480 kcal",
      " Zsír: 23 g, amelyből telített zsírsavak: 14 g",
      " Szénhidrát: 59 g, amelyből cukrok: 57 g",
      " Rost: 6,3 g",
      " Fehérje: 5,2 g",
      " Só: 0,18 g",
    ],
    amount: "25 g",
    allergens: ["diófélék", "tej", " szója"],
    src: "/img/products/26. Nobilis tejcsokolades szAlmaszal.jpg",
  },

  {
    name: "Nobilis minimix",
    content: [
      "aszalt, magozott feketecseresznye (49%)",
      " étcsokoládé pasztilla (16%) (kakaómassza, cukor, kakaóvaj, emulgálószer: szójalecitin, természetes vanília aroma)",
      " ruby csokoládé pasztilla (14%) (cukor, kakaóvaj, zsíros tejpor, kakaómassza, emulgálószer: szójalecitin, antioxidáns: citromsav, természetes vanília aroma)",
      "hámozott, aszalt almahasáb (13%)",
      " hántolt tökmag (8%)",
    ],
    nutrition: [
      "Energia: 1692 kJ/402 kcal",
      "Zsír: 14 g, amelyből telített zsírsavak: 7,2 g",
      " Szénhidrát: 59 g, amelyből cukrok: 56 g",
      "Rost: 5,4 g",
      " Fehérje: 5,8 g",
      " Só: 0,06 g",
    ],
    amount: "25 g",
    allergens: ["diófélék", " tej", " szója"],
    src: "/img//products/25. Nobilis minimix.jpg",
  },

  {
    name: "Garden hidegen préselt alma-eper",
    content: ["80% almalé", " 20% eper gyümölcsvelő"],
    nutrition: [
      "Energia: 173 kJ/41 kcal",
      " Zsír: 0,05 g, amelyből telített zsírsavak: 0,05 g",
      " Szénhidrát: 9,7 g, amelyből cukrok: 9,7 g",
      " Rost: 0,4 g",
      " Fehérje: 0,2 g",
      " Só: 0,002 g",
    ],
    amount: "250 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/14. Garden hidegen preselt alma-eper.jpg",
  },

  // TODO2

  {
    name: "Garden hidegen préselt alma",
    content: ["100% almalé"],
    nutrition: [
      "Energia: 205 kJ/48 kcal",
      " Zsír: 0,1 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 11,6 g, amelyből cukrok: 11,6 g",
      " Rost: 0,4 g",
      " Fehérje: 0,2 g",
      " Só: 0,003 g",
    ],
    amount: "250 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/13. Garden hidegen preselt alma.jpg",
  },

  {
    name: "Garden hidegen préselt alma-körte",
    content: ["80% almalé", " 20% körte gyümölcsvelő"],
    nutrition: [
      "Energia: 188 kJ/44 kcal",
      " Zsír: 0,1 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 10,6 g, amelyből cukrok: 10,6 g",
      " Rost: 0,4 g",
      " Fehérje: 0,2 g",
      " Só: 0,003 g",
    ],
    amount: "250 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/16. Garden hidegen preselt alma-korte.jpg",
  },
  {
    name: "Garden hidegen préselt alma-meggy",
    content: ["80% almalé", " 20% meggy gyümölcsvelő"],
    nutrition: [
      "Energia: 193 kJ/45 kcal",
      " Zsír: 0,1 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 10,7 g, amelyből cukrok: 10,7 g",
      " Rost: 0,4 g",
      " Fehérje: 0,3 g",
      " Só: 0,003 g",
    ],
    amount: "250 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/17. Garden hidegen preselt alma-meggy.jpg",
  },
  {
    name: "Garden hidegen préselt alma-őszi",
    content: ["80% almalé", " 20% őszibarack gyümölcsvelő"],
    nutrition: [
      "Energia: 206 kJ/49 kcal",
      " Zsír: 0,1 g, amelyből telített zsírsavak: 0,1 g",
      " Szénhidrát: 11,5 g, amelyből cukrok: 11,5 g",
      " Rost: 0,4 g",
      " Fehérje: 0,4 g",
      " Só: 0,003 g",
    ],
    amount: "250 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/15. Garden hidegen preselt alma-oszi.jpg",
  },

  {
    name: "Mizse szénsavas ásványvíz",
    content: ["víz"],
    nutrition: [""],
    amount: "500 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/22. Mizse szensavas asvanyviz.jpg",
  },
  {
    name: "Mizse szénsavmentes ásványvíz",
    content: ["víz"],
    nutrition: [""],
    amount: "500 ml",
    allergens: ["allergénmentes"],
    src: "/img/products/23. Mizse szensavmentes asvanyviz.jpg",
  },
];

export default menu_data;

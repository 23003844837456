import React from "react";

// internal
import EmailSvg from "src/styles/img//email.svg";
import PhoneSvg from "src/styles/img//phone.svg";
import NameSvg from "src/styles/img//name.svg";
import LocationSvg from "src/styles/img//location.svg";

const HomeBanner4 = () => {
  const data = [
    {
      icon: EmailSvg,
      key: "Központi email cím",
      value: "info@tizorai.com",
      additional:
        "Kérjük, amennyiben e-mail formájában érdeklődnek, tüntessék fel Tízórai kártyájuk számát a könnyebb beazonosításhoz.",
    },
    {
      icon: NameSvg,
      key: "Telefonszám",
      value: "",
      additional:
        "Kérjük, amennyiben telefonon szeretné felvenni a kapcsolatot ügyfélszolgálatunkkal, küldje el nevét és telefonszámát az info@tizorai.com email címre és felvesszük Önnel a kapcsolatot.",
    },
    {
      icon: LocationSvg,
      key: "A projekt üzemeltetője",
      value: "Uzsi4Kids Nonprofit Kft.",
    },
    {
      icon: PhoneSvg,
      key: "A vállalat székhelye",
      value: "1063 Budapest, Szondi utca 70.",
    },
  ];

  return (
    <>
      <section className="slider__area mt-90 mb-60 z-index-1 p-relative fix contact">
        <div className="container">
          <h2 className="text-center mb-40">Elérhetőség</h2>
          <div className="row">
            {data.map((item, index) => (
              <div
                className="col-lg-6 col-12 text-center d-flex align-items-stretch justify-content-center mb-30"
                key={index}
              >
                <div className="contact__box p-3 ">
                  <div className="contact__svg">
                    <img src={item.icon} alt={item.key} />
                  </div>
                  <p className="contact__p1">{item.key}</p>
                  <p className="contact__p2">{item.value}</p>
                  {item.additional ? (
                    <p className="contact__p3F">{item.additional}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner4;

import React from "react";
import { useNavigate } from "react-router-dom";

// import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PriceList() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`/arlista.pdf?v=4`);
    navigate(0);
  }, [navigate]);

  return null;
}

export default PriceList;

// import logo from "./logo.svg";
import "./styles/index.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AlwaysTop from "./layout/AlwaysTop";
import HomePage from "./pages/index";
import ErrorPage from "./pages/404";
import ASZF from "./pages/aszf";
import GDPR from "./pages/gdpr";
import GYIK from "./pages/gyik";
import SchoolPage from "./pages/iskolak-reszere";
import ParentsPage from "./pages/szulok-reszere";
import CompanyPage from "./pages/vallalatok-reszere";
import ContactPage from "./pages/kapcsolat";
import ProductsPage from "./pages/termekek";
import PriceList from "./pages/arlista";
import ParentsList from "./pages/szorolap";
import TopupPage from "./pages/egyenleg-feltoltese";

function App() {
  return (
    <BrowserRouter>
      <AlwaysTop>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/arlista" element={<PriceList />} />
          <Route exact path="/szorolap" element={<ParentsList />} />
          <Route exact path="/404" element={<ErrorPage />} />
          <Route exact path="/aszf" element={<ASZF />} />
          <Route exact path="/gdpr" element={<GDPR />} />
          <Route exact path="/gyik" element={<GYIK />} />
          <Route exact path="/iskolak-reszere" element={<SchoolPage />} />
          <Route exact path="/szulok-reszere" element={<ParentsPage />} />
          <Route exact path="/vallalatok-reszere" element={<CompanyPage />} />
          <Route exact path="/kapcsolat" element={<ContactPage />} />
          <Route exact path="/termekek" element={<ProductsPage />} />
          <Route exact path="/egyenleg-feltoltese" element={<TopupPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AlwaysTop>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import { Helmet } from "react-helmet-async";
import shape1 from "src/styles/img/payment1.svg";
import shape2 from "src/styles/img/payment2.svg";
import shape3 from "src/styles/img/payment3.svg";
import shape4 from "src/styles/img/payment4.svg";
import shape5 from "src/styles/img/payment5.svg";
import shape6 from "src/styles/img/payment6.svg";

import { backend, backendAPI } from "src/components/constants";

const Content = () => {
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      card: searchParams.get("card") ?? "",
      amount: searchParams.get("amount") ?? "",
    },
    validationSchema: Yup.object({
      card: Yup.string()
        .max(255, "Túl hosszú kártyaszám.")
        .required("A kártya számát kötelező megadni."),
      amount: Yup.number()
        .typeError("Kérem, csak számokat adjon meg.")
        .required("Kérem, adja meg a feltölteni kívánt összeget.")
        .min(200, "A feltöltendő összeg minimális értéke 200 forint.")
        .max(
          50000,
          "A feltöltendő összeg nem haladhatja meg az 50 000 forintot."
        ),
    }),
    onSubmit: async (values, helpers) => {
      axios({
        url: backendAPI + "/card?card=" + values.card,
        method: "get",
        // withCredentials: false,
        // headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((message) => {
          if (message.data) {
            window.location.replace(
              `${backend}/?card=${values.card}&amount=${values.amount}`
            );
          } else {
            formik.setErrors({ card: "A megadott kártyaszám nem létezik." });
          }
        })
        .catch((err) => {
          formik.setErrors({ card: "A megadott kártyaszám nem létezik." });
        });
      return;
    },
  });

  return (
    <>
      <section className="slider__area pt-160 z-index-1 p-relative fix wallet1">
        <div className="container ">
          <div className="row mb-40 ">
            <h2 className="text-center">
              Gyors egyenlegfeltöltés gyermeke <br /> Tízórai kártyájára
            </h2>
            <p className="text-center">
              Itt tudja feltölteni gyermeke Tízórai kártyáját a fizetési kapun
              keresztül. Egyéb mód a feltöltésre: átutalással bankszámláról.
              További információkat találhat az alábbi linken:{" "}
              <Link to="/szulok-reszere">
                <span>www.tizorai.com/szulok-reszere</span>
              </Link>
            </p>
          </div>
        </div>
      </section>

      <section className="slider__area mb-50 z-index-1 p-relative fix wallet2">
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="colored mb-50 pb-50 pt-50">
            <div className="container">
              <div className="row text-center">
                <p className="info1">
                  Adja meg a Tízórai <strong>kártya számát</strong> (Közlemény),
                  a feltölteni kívánt összeget, majd kattintson a Fizetési kapu
                  alatt a<strong> Fizetés</strong> gombra.
                </p>

                <div className="mb-3 col-12">
                  <input
                    type="text"
                    name="card"
                    className="form-control"
                    placeholder="Kártya száma"
                    value={formik.values.card}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.card && (
                    <div className="text-danger">{formik.errors.card}</div>
                  )}
                </div>
                <div className="mb-3 col-12">
                  <input
                    type="text"
                    name="amount"
                    className="form-control"
                    placeholder="Összeg (Ft)"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.amount && (
                    <div className="text-danger">{formik.errors.amount}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <h2>Fizetési kapu</h2>
            <p className="mb-30">
              A Tízórai kártyára az Ön által megadott összeg kerül feltöltésre.
            </p>

            <div className="header__btn-7 mb-40">
              <button type="submit" className="tp-btnr tp-btn-shine-effect">
                Fizetés
              </button>
            </div>
          </div>
        </form>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center">
            {[shape1, shape2, shape3, shape4, shape5, shape6].map(
              (shape, index) => (
                <div
                  className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-30 text-center"
                  key={index}
                >
                  <img src={shape} alt="fizetési eszköz" />
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const TopupPage = () => {
  return (
    <>
      <Helmet>
        <title>Egyenleg feltöltése</title>
        <meta name="description" content="Egyenleg feltöltése" />
        <link rel="canonical" href="/egyenleg-feltoltese" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Content />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default TopupPage;

import { useEffect, useState } from "react";

import shape from "src/styles/img/hero.png";
import { Link } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const InformationDialog = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const display = JSON.parse(localStorage.getItem("info1Show") ?? true);
    setOpen(display);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Általános információk"}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="h5">
            Tízórai kártyát a projektben résztvevő iskolák titkárságán lehet
            igényelni!{" "}
          </Typography>

          <Typography gutterBottom variant="h7">
            <div className=" mb-20">
              A tízórai kártya feltöltésére 2 mód van:
              <ul>
                <li className="ml-30">
                  A fizetési kapun keresztül bankkártyás fizetéssel, melynél a
                  jóváírás a kártyára 1-2 órán belül megtörténik.
                </li>
                <li className="ml-30">
                  Átutalással bankszámláról, melynél a jóváírás a kártyára 1-2
                  munkanap alatt történik meg. A Közlemény rovatba
                  elengedhetetlen helyesen megadni a Tízórai kártya 8
                  számjegyét. Kérjük, egyéb információt ne írjanak bele.
                </li>
              </ul>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <div
            className="header__btn-7 d-flex align-items-center"
            onClick={() => {
              handleClose();
              localStorage.setItem("info1Show", false);
            }}
          >
            <Link to="/" className="tp-btnr tp-btn-shine-effect ">
              Rendben
            </Link>
          </div>
          <div
            className="header__btn-7 d-flex align-items-center"
            onClick={handleClose}
          >
            <Link to="" className="tp-btnr tp-btn-shine-effect">
              Bezár
            </Link>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const HomeBanner1 = () => {
  return (
    <>
      <InformationDialog />
      <section
        className="slider__area pt-140 z-index-1 p-relative fix banner1 mb-60"
        // data-bg-color="green-light-3"
      >
        <div className="container">
          <div className="row align-items-center ">
            {/* <div className="col-12">
              <h3 className="mb-20 text-center">
                Tízórai kártyát a projektben résztvevő iskolák titkárságán lehet
                igényelni!{" "}
              </h3>
            </div> */}
            <div className="col-lg-5">
              {/* <div className="banner1__info mb-20">
                A tízórai kártya feltöltésére 2 mód van:
                <ul>
                  <li className="ml-30">
                    A fizetési kapun keresztül bankkártyás fizetéssel, melynél a
                    jóváírás a kártyára 1-2 órán belül megtörténik.
                  </li>
                  <li className="ml-30">
                    Átutalással bankszámláról, melynél a jóváírás a kártyára 1-2
                    munkanap alatt történik meg. A Közlemény rovatba
                    elengedhetetlen helyesen megadni a Tízórai kártya 8
                    számjegyét. Kérjük, egyéb információt ne írjanak bele.
                  </li>
                </ul>
              </div> */}
              <h1>Üdvözlünk a Tízórai oldalán!</h1>
              <p>
                Tízórai kártyák, okos automata, egészséges termékek, egy szuper
                projekt. További információkért görgess lejjebb.
              </p>
              <div className="header__btn-7 d-flex align-items-center">
                <Link
                  to="/egyenleg-feltoltese"
                  className="tp-btnr tp-btn-shine-effect d-none d-sm-inline-block "
                >
                  Egyenleg feltöltése
                </Link>
              </div>
            </div>
            <div className="col-lg-7 ">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src={shape}
                  alt="shape"
                  layout="responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner1;

import React from "react";
import BackToTopCom from "../components/common/scroll-to-top";

const Wrapper = ({ children }) => {
  return (
    <>
      {children}
      <BackToTopCom />
    </>
  );
};

export default Wrapper;

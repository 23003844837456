import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactCardFlip from "react-card-flip";

import shape_plus from "src/styles/img/plus.svg";
import shape_close from "src/styles/img/close.svg";
import menu_data from "src/data/products-data";

const Product = (props) => {
  const [flipped, setFlipped] = useState(false);

  return (
    <div
      className={`col-lg-3 col-md-4 col-sm-6 col-12 mb-40 p-relative d-flex justify-content-center `}
    >
      <div className={`${flipped ? "product__flipped" : ""}`}>
        <ReactCardFlip
          isFlipped={flipped}
          flipSpeedBackToFront={2}
          flipSpeedFrontToBack={2}
          flipDirection="vertical"
          containerStyle={{ height: "100%" }}
        >
          <div
            className="product text-center d-flex flex-column align-items-center justify-content-stretch"
            style={{ height: "100%" }}
          >
            <img
              src={props.src}
              alt={props.description}
              // layout="responsive"
              width={150}
              height={150}
            />
            <h3 className="pb-0">{props.name}</h3>
            {/* <p>{props.description}</p> */}
            <div onClick={() => setFlipped(!flipped)} className="mt-auto">
              <img src={shape_plus} alt="more info button" />
            </div>
          </div>

          <div className=" product d-flex flex-column align-items-center text-center">
            <img
              src={props.src}
              alt={props.name}
              // layout="responsive"
              width={130}
              height={130}
            />
            {/* <h2 className="mb-0">{props.price} FT</h2> */}
            <h3 className="pb-0 mb-5">{props.name}</h3>

            {/* <div className="content">
              <h4 className="mb-5">Összetétel</h4>
              {props.content.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div> */}

            <div className="allergens">
              <h4 className="mb-5">Allergének</h4>
              {props.allergens.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>

            <div className="nutrition">
              <h4 className="mb-5">Tápértékek</h4>
              {props.nutrition.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>

            <div className="amount">
              <h4 className="mb-5">Kiszerelés</h4>
              <p>{props.amount}</p>
            </div>

            <div onClick={() => setFlipped(!flipped)}>
              <img
                src={shape_close}
                alt="Close button"
                className="p-absolute product__close"
                // layout="responsive"
              />
            </div>
          </div>
        </ReactCardFlip>
      </div>
    </div>
  );
};

const Products3 = () => {
  return (
    <>
      <section className="slider__area pt-60 z-index-1 p-relative fix products3">
        <div className="container">
          <div>
            <h2 className="text-center">Termékek</h2>
            <div className="text-center">
              <p>
                Termékkínálatunk kialakítása során a diákok, a szülők és a
                tanárok véleményére helyezzük a legnagyobb hangsúlyt. Kérjük, ha
                ötlete vagy tanácsa lenne,{" "}
                <Link to="/kapcsolat" className="underline">
                  vegye fel velünk a kapcsolatot.
                </Link>
              </p>
            </div>
          </div>
          <div className="row mt-70 mb-70 justify-content-center d-flex">
            {menu_data.map((item, index) => (
              <Product {...item} key={index} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Products3;

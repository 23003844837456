import React from "react";

const ErrorSvg = (props) => ( <
    svg xmlns = "http://www.w3.org/2000/svg"
    xmlnsXlink = "http://www.w3.org/1999/xlink"
    id = "Layer_1"
    x = "0px"
    y = "0px"
    width = { 512 }
    height = { 512 }
    viewBox = "0 0 512 512"
    enableBackground = "new 0 0 512 512"
    xmlSpace = "preserve" {...props } >
    <
    g id = "food_machine" >
    <
    path fill = "#424953"
    d = "M96.007,501.339c0,5.891-4.773,10.656-10.664,10.656c-5.891,0-10.672-4.766-10.672-10.656   s4.781-10.671,10.672-10.671C91.233,490.668,96.007,495.448,96.007,501.339z" /
    >
    <
    path fill = "#424953"
    d = "M437.345,501.339c0,5.891-4.781,10.656-10.688,10.656c-5.875,0-10.656-4.766-10.656-10.656   s4.781-10.671,10.656-10.671C432.563,490.668,437.345,495.448,437.345,501.339z" /
    >
    <
    rect x = { 64.008 }
    y = { 10.677 }
    fill = "#646C77"
    width = { 383.992 }
    height = { 479.991 }
    />{" "} <
    path fill = "#A9B1BC"
    d = "M362.658,202.673c0,5.891-4.766,10.672-10.656,10.672s-10.655-4.781-10.655-10.672   s4.765-10.672,10.655-10.672S362.658,196.782,362.658,202.673z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M394.657,202.673c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672   s4.766-10.672,10.656-10.672S394.657,196.782,394.657,202.673z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M426.657,202.673c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672   s4.766-10.672,10.656-10.672S426.657,196.782,426.657,202.673z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M362.658,234.672c0,5.891-4.766,10.672-10.656,10.672s-10.655-4.781-10.655-10.672   c0-5.89,4.765-10.671,10.655-10.671S362.658,228.782,362.658,234.672z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M394.657,234.672c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672   c0-5.89,4.766-10.671,10.656-10.671S394.657,228.782,394.657,234.672z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M426.657,234.672c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672   c0-5.89,4.766-10.671,10.656-10.671S426.657,228.782,426.657,234.672z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M362.658,266.672c0,5.891-4.766,10.672-10.656,10.672s-10.655-4.781-10.655-10.672S346.111,256,352.002,256   S362.658,260.781,362.658,266.672z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M394.657,266.672c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672S378.11,256,384.001,256   S394.657,260.781,394.657,266.672z" /
    >
    <
    path fill = "#A9B1BC"
    d = "M426.657,266.672c0,5.891-4.766,10.672-10.656,10.672s-10.656-4.781-10.656-10.672S410.11,256,416.001,256   S426.657,260.781,426.657,266.672z" /
    >
    <
    path fill = "#4FC0E8"
    d = "M416.001,149.346h-63.999l0,0c-5.891,0-10.655,4.766-10.655,10.656s4.765,10.671,10.655,10.671l0,0h63.999   c5.891,0,10.656-4.781,10.656-10.671S421.892,149.346,416.001,149.346z" /
    >
    <
    rect x = { 351.893 }
    y = { 362.67 }
    fill = "#646C77"
    width = { 64.218 }
    height = { 63.998 }
    />{" "} <
    path fill = "#A9B1BC"
    d = "M394.657,373.342c0,5.891-4.766,10.656-10.656,10.656s-10.656-4.766-10.656-10.656   s4.766-10.672,10.656-10.672S394.657,367.451,394.657,373.342z" /
    >
    <
    rect x = { 74.671 }
    y = { 21.348 }
    fill = "#F4F6F9"
    width = { 234.675 }
    height = { 319.994 }
    />{" "} <
    path fill = "#A9B1BC"
    d = "M128.006,426.668c-11.766,0-21.335-9.562-21.335-21.327c0-11.766,9.57-21.343,21.335-21.343h127.998   c11.766,0,21.343,9.577,21.343,21.343c0,11.765-9.577,21.327-21.343,21.327H128.006z" /
    >
    <
    g >
    <
    rect x = { 85.343 }
    y = { 42.676 }
    fill = "#AC92EA"
    width = { 63.999 }
    height = { 85.327 }
    />{" "} <
    /g>{" "} <
    g >
    <
    rect x = { 160.006 }
    y = { 149.346 }
    fill = "#46CEAD"
    width = { 63.999 }
    height = { 85.326 }
    />{" "} <
    /g>{" "} <
    g >
    <
    polygon fill = "#5E9CEA"
    points = "224.004,42.676 160.006,42.676 170.669,63.926 160.006,128.002 224.004,128.002 213.341,63.926       " /
    >
    <
    /g>{" "} <
    g >
    <
    polygon fill = "#4FC0E8"
    points = "298.659,149.346 234.668,149.346 245.34,170.595 234.668,234.672 298.659,234.672     288.003,170.595   " /
    >
    <
    /g>{" "} <
    g >
    <
    path fill = "#FECD57"
    d = "M298.659,53.348c0-5.891-14.312-10.672-31.991-10.672c-17.672,0-32,4.781-32,10.672    c0,0.031,0,74.655,0,74.655h63.991C298.659,128.002,298.659,53.379,298.659,53.348z" /
    >
    <
    /g>{" "} <
    g >
    <
    rect x = { 234.668 }
    y = { 256 }
    fill = "#5E9CEA"
    width = { 63.991 }
    height = { 85.342 }
    />{" "} <
    /g>{" "} <
    g >
    <
    polygon fill = "#FB6D51"
    points = "149.342,256 85.343,256 96.007,277.25 85.343,341.342 149.342,341.342 138.67,277.25   " /
    >
    <
    /g>{" "} <
    g >
    <
    path fill = "#9ED36A"
    d = "M224.004,266.672c0-5.891-14.328-10.672-32-10.672c-17.671,0-31.999,4.781-31.999,10.672    c0,0.047,0,74.67,0,74.67h63.999C224.004,341.342,224.004,266.719,224.004,266.672z" /
    >
    <
    /g>{" "} <
    g >
    <
    path fill = "#ED5564"
    d = "M149.342,160.018c0-5.906-14.328-10.672-32-10.672c-17.671,0-31.999,4.766-31.999,10.672    c0,0.031,0,74.654,0,74.654h63.999C149.342,234.672,149.342,160.049,149.342,160.018z" /
    >
    <
    /g>{" "} <
    path fill = "#424953"
    d = "M53.344,0.005v501.334h405.312V0.005H53.344z M309.347,21.348v85.327H74.671V21.348H309.347z    M74.671,213.345v-85.342h234.675v85.342H74.671z M309.347,234.672v85.326H74.671v-85.326H309.347z M74.671,479.996V341.342   h234.675v138.654H74.671z M437.345,479.996H330.659V21.348h106.686V479.996z" /
    >
    <
    path fill = "#424953"
    d = "M341.222,351.998v85.342h85.561v-85.342H341.222z M405.438,415.997h-42.874v-42.655h42.874V415.997z" /
    >
    <
    /g>{" "} <
    /svg>
);
export default ErrorSvg;
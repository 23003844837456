import { Link } from "react-router-dom";
import React from "react";
// internal
import menu_data from "src/data/menu-data";

const Menus = () => {
  return (
    <ul className="text-end">
      {menu_data.map((menu, i) => (
        <li key={i} className={`${menu.hasDropdown ? "has-dropdown" : ""}`}>
          <Link to={menu.link}>{menu.title}</Link>
          {menu.hasDropdown && (
            <ul className="submenu">
              {menu.submenus.map((sub, i) => (
                <li key={i}>
                  <Link to={sub.link}>{sub.title}</Link>
                </li>
              ))}
            </ul>
          )}
          {menu.mega_menus && (
            <ul className="mega-menu">
              {menu.mega_menus.map((mega, i) => (
                <li key={i}>
                  <Link to={mega.link} className="mega-menu-title">
                    {mega.title}
                  </Link>
                  <ul>
                    {mega.submenus.map((sub_mega, i) => (
                      <li key={i}>
                        <Link to={sub_mega.link}>{sub_mega.title}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Menus;

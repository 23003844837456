import React from "react";
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";

import shape_parents from "src/styles/img/szuloknek.png";
import shape_logo1 from "src/styles/img/parents1.svg";
import shape_logo2 from "src/styles/img/parents2.svg";
import shape_download from "src/styles/img//download.svg";

const Content = () => {
  return (
    <section className="slider__area pt-160 mb-50 z-index-1 p-relative fix parents">
      <div className="container ">
        <div className="row mb-40">
          <div className="col-md-7">
            <div className="left">
              <h1>Miért érdemes gyermekének igényelnie?</h1>
              <p>
                Csak és kizárólag <strong>egészséges termékkínálat</strong>,
                minimális hozzáadott cukor- és sótartalmú termékekkel.
              </p>
              <p>
                Naprakész, elérhető információk, szabályozások és felügyelet.
              </p>
              <p>
                Év közbeni szórakoztató programok szervezése a diákok és szüleik
                számára: rajzversenyek, termékkóstolók és szavazások a
                kínálatról
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={shape_parents} alt="shape" />
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-6 mb-30">
            <div className="row ">
              <div className="col-2 ">
                <img className="pr-10" src={shape_logo1} alt="shape" />
              </div>
              <div className="col-9">
                <h4>Készpénzmentes fizetés:</h4>
                <p>
                  Tízórai kártyácskával gyermekének nincs szüksége készpénzre,
                  ezáltal biztonságos módon juthat hozzá egészséges
                  harapnivalókhoz.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-2">
                <img className="pr-10" src={shape_logo2} alt="shape" />
              </div>
              <div className="col-9">
                <h4>Mobilapplikáció:</h4>
                <p>
                  Kényelmes használat, nyomon követhető napi vásárlások,
                  szabályozások és korlátozások kialakítása például allergia
                  esetén.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <h2>Tájékoztató a szülők részére</h2>
            Amennyiben kérdése lenne a Tízóraival kapcsolatban, tekintse meg az
            alábbi <br />
            szórólapot vagy vegye fel velünk a kapcsolatot az{" "}
            <strong className="highlight">info@tizorai.com</strong> e-mail
            címen.
          </div>
          <div className="col-12 text-center">
            <Link to="/szorolap" rel="noopener noreferrer" target="_blank">
              <img className="" src={shape_download} alt="shape" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const ParentsPage = () => {
  return (
    <>
      <Helmet>
        <title>Tízórai szülők részére</title>
        <meta
          name="description"
          content="Egészséges termékkínálat, minimális hozzáadott cukor- és sótartalmú termékekkel. Naprakész, elérhető információk, szabályozások és felügyelet."
        />
        <link rel="canonical" href="/szulok-reszere" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Content />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default ParentsPage;

import React from "react";
// internal
// import shape from "src/styles/img/offcanvas-shape-1.png";
import logo from "src/styles/img/logo.png";
import SocialLinks from "src/components/social";
import MobileMenus from "./mobile-menus";
import { Link } from "react-router-dom";

const OffCanvasSix = ({ isOffCanvasOpen, setIsOffCanvasOpen }) => {
  return (
    <React.Fragment>
      <div
        className={`offcanvas__area offcanvas__area-1 ${
          isOffCanvasOpen ? "offcanvas-opened" : ""
        }`}
      >
        <div className="offcanvas__wrapper">
          {/* <div className="offcanvas__shape">
            <img className="offcanvas__shape-1" src={shape} alt="shape" />
          </div> */}
          <div className="offcanvas__close">
            <button
              onClick={() => setIsOffCanvasOpen(false)}
              className="offcanvas__close-btn offcanvas-close-btn"
            >
              <i className="fa-regular fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas__content">
            <div className="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
              <div className="offcanvas__logo logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="mobile-menu-3 fix mb-40 menu-counter mean-container d-block">
              <div className="mean-bar">
                {/* MobileMenus start*/}
                <MobileMenus />
                {/* MobileMenus end*/}
              </div>
            </div>

            <div className="offcanvas__btn">
              <div className="header__btn-7 d-flex align-items-center">
                <Link
                  to="/kapcsolat"
                  className="tp-btnr tp-btn-shine-effect  d-sm-inline-block "
                >
                  Lépjen velünk kapcsolatba
                </Link>
              </div>
            </div>
            <div className="offcanvas__social">
              <h3 className="offcanvas__social-title">Kövessen minket :</h3>
              <SocialLinks />
            </div>
            <div className="offcanvas__contact">
              <p className="offcanvas__contact-mail">
                <Link to="mailto:tizorai@tizorai.com">
                  {" "}
                  tizorai@tizorai.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* overlay */}
      <div
        onClick={() => setIsOffCanvasOpen(false)}
        className={`body-overlay ${isOffCanvasOpen ? "opened" : ""}`}
      ></div>
      {/* overlay */}
    </React.Fragment>
  );
};

export default OffCanvasSix;

import React from "react";
import { Helmet } from "react-helmet-async";

import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import shape_parents from "src/styles/img//vallalatoknak.jpg";
import shape_logo from "src/styles/img//corporates.svg";

const Content = () => {
  return (
    <section className="slider__area pt-180 mb-80 z-index-1 p-relative fix corporates">
      <div className="container ">
        <div className="row mb-60">
          <div className="col-md-7">
            <div className="left">
              <h1>Együttműködés vállalatokkal</h1>
              <p>
                <strong>Nyitottak vagyunk</strong> olyan egészséges termékek
                forgalmazására, melyek megfelelnek előírásainknak és a diákok
                igényeinek.
              </p>
              <p>
                Szeretnénk ösztönözni a vállalatokat az{" "}
                <strong>egészséges termékpaletta</strong> bővítésére és
                fejlesztésére.
              </p>
              <p>
                Partneri kapcsolat keretein belül közös projektek indítása a
                gyermekek számára legelőnyösebb konstrukcióban.
              </p>
            </div>
          </div>
          <div className="col-md-5 d-flex flex-column justify-content-center">
            <img className="img-fluid" src={shape_parents} alt="shape" />
          </div>
        </div>

        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-3 text-center">
            <img className="" src={shape_logo} alt="shape" />
          </div>
          <div className="col-12 col-md-6 text-center">
            <h4>
              Partnereink{" "}
              <span className="highlight2">érdeklődését és észrevételeit</span>{" "}
              a <span className="highlight2">penzugy@tizorai.com</span> e-mail
              címre várjuk.
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

const CompanyPage = () => {
  return (
    <>
      <Helmet>
        <title>Tízórai vállalatok részére</title>
        <meta
          name="description"
          content="Együttműködés a vállalatokkal. Partneri kapcsolat keretein belül közös projektek indítása a gyermekek számára. A vállalatok egészséges termékekkel való kibővítése."
        />
        <link rel="canonical" href="/vallalatok-reszere" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Content />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default CompanyPage;

import { Link } from "react-router-dom";

const SEO = ({ font }) => (
  <>
    <header>
      <title>Tízórai</title>
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      {font && <Link to={font} rel="stylesheet" />}
      <link rel="icon" href="/favicon.ico?v=10" />
    </header>
  </>
);

export default SEO;

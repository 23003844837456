import React from "react";
import { Link } from "react-router-dom";
// internal
import shape_insta from "../../styles/img/instagram.svg";
import shape_fb from "../../styles/img/facebook.svg";

const FooterSix = ({ style_2 = false }) => {
  return (
    <footer>
      <div className="footer pt-60 pb-60">
        <div className="container">
          <div className="row ">
            <div className="col-md-4 col-12 align-items-center d-flex ">
              <p className="mb-0">Copyright 2023 Tízórai</p>
            </div>
            <div className="col-md-8 col-12 d-flex justify-content-center align-items-center">
              <Link
                to="/arlista.pdf"
                className="mr-20 footer__icon"
                target="_blank"
              >
                <span className="footer__icon">Árlista</span>
              </Link>

              <Link to="/gyik" className="mr-20 footer__icon">
                <span className="footer__icon">GYIK</span>
              </Link>
              <Link to="/aszf" className="mr-20 footer__icon">
                <span>ÁSZF</span>
              </Link>
              <Link to="/gdpr" className="mr-20 footer__icon">
                <span>GDPR</span>
              </Link>
              <Link
                to="https://www.facebook.com/tizoraimagyarorszag"
                className="mr-20"
                target="_blank"
              >
                <img className="" src={shape_fb} alt="facebook" />
              </Link>
              <Link
                to="https://www.instagram.com/tizorai_magyarorszag"
                target="_blank"
              >
                <img className="" src={shape_insta} alt="instagram" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSix;

import React from "react";

import shape1 from "src/styles/img/partner1.svg";
import shape2 from "src/styles/img/partner2.svg";
import shape3 from "src/styles/img/partner3.svg";
import shape4 from "src/styles/img/partner4.png";
import shape5 from "src/styles/img/partner5.png";
import shape6 from "src/styles/img/partner6.png";
import shape7 from "src/styles/img/partner7.png";
import shape8 from "src/styles/img/partner8.png";

const Products2 = () => {
  const partners = [
    shape1,
    shape2,
    shape3,
    shape4,
    shape5,
    shape6,
    shape7,
    shape8,
  ].map((item, index) => (
    <div className="col-lg-3 d-flex justify-content-center mb-40" key={index}>
      <img className="img-fluid" src={item} alt="shape" />
    </div>
  ));

  return (
    <>
      <section
        className="slider__area pt-60 z-index-1 p-relative fix"
        // data-bg-color="green-light-3"
      >
        <div className="container">
          <h2 className="text-center mb-40">Partnereink</h2>
          <div className="row align-items-center justify-content-center ">
            {partners}
          </div>
        </div>
      </section>
    </>
  );
};

export default Products2;

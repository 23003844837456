import React from "react";
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import { Helmet } from "react-helmet-async";

import school_shape from "src/styles/img//school.jpg";
import students_shape from "src/styles/img//students.png";

const Banner1 = () => {
  return (
    <section
      className="slider__area pt-160 mb-50 z-index-1 p-relative fix"
      // data-bg-color="green-light-3"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h1 className="mb-30">
              Miért érdemes az Ön iskolájának is csatlakoznia?
            </h1>
            <p className="school__p1">
              Célunk a{" "}
              <strong>
                magyarországi általános iskolák és gimnáziumok diákjainak és
                szüleiknek
              </strong>{" "}
              megfelelő megoldást nyújtani a Tízórai kérdésében.
            </p>
            <p className="school__p1">
              <strong>Egészséges termékkínálatunk</strong> által a gyermekek már
              fiatal korban megismerkedhetnek az egészséges termékekkel, s
              megtalálhatják a számukra legmegfelelőbbeket.
            </p>
          </div>
          <div className="col-md-6">
            <img className="img-fluid" src={school_shape} alt="shape" />
          </div>
        </div>
      </div>
    </section>
  );
};
const Banner2 = () => {
  return (
    <section
      className="slider__area  mb-30 z-index-1 p-relative fix"
      // data-bg-color="green-light-3"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-30">
            <img className="img-fluid" src={students_shape} alt="shape" />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <p className="school__p1">
              Szórakoztató programok, versenyek szervezésével szeretnénk bevonni
              a gyermekeket és szüleiket a termékkínálat kialakításába, a
              Tízórai kártyácskák tervezésébe, a szolgáltatás fejlesztésébe.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const Banner3 = () => {
  return (
    <section className="slider__area mb-30 z-index-1 p-relative fix text-center banner3">
      <h2>
        Mire van szükségünk az iskolák <br /> részéről?
      </h2>
      <p>Egy konnektorra, és semmi másra! :)</p>
    </section>
  );
};

const Banner4 = () => {
  const data = [
    "Minimális energiaszükséglet",
    "Minimális területigény",
    "Nincsenek fenntartási költségek és bérigény",
    "0-24-es elérhetőség a hét minden napján",
    "Tanárok, iskolai alkalmazottak részére is rendelkezésre áll",
    "Innovatív, egyedi, szórakoztató!",
  ];

  return (
    <section className="slider__area mb-60 pb-20 z-index-1 p-relative fix text-center">
      <div className="container">
        <h2 className="mb-50">Miért éri meg az iskoláknak?</h2>
        <div className="row ">
          {data.map((text, index) => (
            <div
              className="col-md-4 mb-40 d-flex align-items-stretch"
              key={index}
            >
              <div className="school__box mr-25 ml-25 d-flex align-items-center justify-content-center">
                <p className="school__p2 ">{text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const SchoolPage = () => {
  return (
    <>
      <Helmet>
        <title>Tízórai iskolák részére</title>
        <meta
          name="description"
          content="Célunk a magyarországi általános iskolák és gimnáziumok diákjainak megfelelő megoldást nyújtani a Tízórai kérdésében - egészséges termékkínálat."
        />
        <link rel="canonical" href="/iskolak-reszere" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Banner1 />
        <Banner2 />
        <Banner3 />
        <Banner4 />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default SchoolPage;

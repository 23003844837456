import React from "react";
import { FooterSix, HeaderSix, Wrapper } from "../layout/index";
import { Helmet } from "react-helmet-async";
import SEO from "src/components/seo";
import HomeBanner1 from "src/components/hero-banners/home-banner1";
import HomeBanner2 from "src/components/hero-banners/home-banner2";
import HomeBanner3 from "src/components/hero-banners/home-banner3";
import HomeBanner4 from "src/components/hero-banners/home-banner4";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Tízórai</title>
        <meta
          name="description"
          content="Egyszerre finom és egészséges termékek - termékkínálatunk kialakítása során a diákok, a szülők és a tanárok véleményére helyezzük a legnagyobb hangsúlyt."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <HomeBanner1 />
        <HomeBanner2 />
        <HomeBanner3 />
        <HomeBanner4 />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default HomePage;

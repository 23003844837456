// external
import { Link } from "react-router-dom";
// internal
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import { ErrorSvg } from "src/svg/index";

export default function ErrorPage() {
  return (
    <Wrapper>
      <SEO pageTitle={"A keresett oldal nem található"} />
      <HeaderSix top_bar={false} header_solid={true} commonOffCanvas={true} />
      {/* error area start */}
      <section className="error__area error__bg pt-220 pb-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="error__wrapper p-relative z-index-1 text-center">
                <h3 className="error__text">404</h3>
                <div className="error__thumb mb-35">
                  <ErrorSvg />
                </div>
                <div className="error__content">
                  <h3 className="error__title">Hoppá!</h3>
                  <p>A keresett oldal nem található</p>
                  <div className="header__btn-7 d-flex align-items-center justify-content-center">
                    <Link
                      to="/"
                      className="tp-btnr tp-btn-shine-effect  d-sm-inline-block "
                    >
                      Vissza a főoldalra
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* error area end */}
      <FooterSix style_2={true} />
    </Wrapper>
  );
}

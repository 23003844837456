import React from "react";
import { useNavigate } from "react-router";

// import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ParentsList = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(`/szorolap.png`);
    navigate(0);
  }, [navigate]);

  return null;
};

export default ParentsList;

import React from "react";

// internal
import shape from "src/styles/img/hero2.png";
import shape_app from "src/styles/img/appstore.png";
import shape_play from "src/styles/img/playstore.png";
import shape_telephone_arrow from "src/styles/img/telehone-arrow.png";
import { Link } from "react-router-dom";

const HomeBanner2 = () => {
  return (
    <>
      <section className="slider__area pt-20 z-index-1 p-relative fix text-center text-md-start">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-3">
              <div className="d-flex justify-content-center">
                <img className="" src={shape} alt="shape" />
              </div>
            </div>
            <div className="col-lg-9 hero2__right">
              <h2>
                A mobilalkalmazás letölthető a Google Play és az App Store
                áruházakból.
              </h2>
              <p>
                Ha gyermeked iskolája is részt vesz projektünkben, töltsd le
                mobilalkalmazásunkat és légy részese te is a Tízórainak.
              </p>
              <div className="d-flex">
                <div className="pl-10 pr-30">
                  <img className="" src={shape_telephone_arrow} alt="shape" />
                </div>
                <div className="d-flex flex-column">
                  <Link
                    to="https://apps.apple.com/hu/app/t%C3%ADz%C3%B3rai/id6445867832"
                    target="_blank"
                  >
                    <img className="" src={shape_app} alt="shape" />
                  </Link>

                  <Link
                    to="https://play.google.com/store/apps/details?id=hu.tizorai.mobile"
                    target="_blank"
                  >
                    <img className="" src={shape_play} alt="shape" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner2;

import React from "react";
import { Helmet } from "react-helmet-async";

import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";
import faq_data from "src/data/faq-data";
import { Link } from "react-router-dom";

const Item = ({ id, question, answer }) => {
  return (
    <div class="accordion-item">
      <h2 class="accordion-header" id={`panelsStayOpen-heading${id}`}>
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#panelsStayOpen-collapse${id}`}
          aria-expanded="false"
          aria-controls={`panelsStayOpen-collapse${id}`}
        >
          {question}
        </button>
      </h2>
      <div
        id={`panelsStayOpen-collapse${id}`}
        class="accordion-collapse collapse"
        aria-labelledby={`panelsStayOpen-heading${id}`}
      >
        <div class="accordion-body">{answer}</div>
      </div>
    </div>
  );
};

const QA = () => {
  return (
    <section className="slider__area pt-140 mb-60 z-index-1 p-relative fix faq">
      <div className="container">
        <div className="row">
          <h1 className="ml-15">Gyakori kérdések</h1>
          <div className="faq__container mb-40">
            <div
              class="accordion accordion-flush"
              id="accordionPanelsStayOpenExample"
            >
              {faq_data.map((item, index) => (
                <Item id={index} {...item} />
              ))}
            </div>
          </div>
          <div className="header__btn-7 d-flex justify-content-center">
            <Link
              href="/contact"
              className="tp-btnr tp-btn-shine-effect d-none d-sm-inline-block "
            >
              Hagyjon üzenetet
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>Gyakori kérdések és válaszok</title>
        <meta
          name="description"
          content="Gyakori kérdések és válaszok a Tízórai automatával kapcsolatban - Tedd fel a kérdésed!"
        />
        <link rel="canonical" href="/gyik" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <QA />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default FAQ;

import React from "react";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet-async";

import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";

const ASZF1 = () => {
  return (
    <div id="1">
      <h1>I. Az alapfogalmak meghatározása</h1>
      <div className="aszf__section">
        <p>
          1. <br /> Üzemeltető: Uzsi4Kids Nonprofit Kft. <br />
          Székhelye: 1063 Budapest, Szondi utca 70. <br /> Adószáma:
          32189117-2-42 <br /> Cégjegyzékszáma: 01 09 411117 <br />
        </p>
        <p>
          Az iskolai automata egy iskolában elhelyezett automata, amelyen
          keresztül a termékek kiadása történik.
        </p>
        <p>
          Tízórai: az Uzsi4Kids Nonprofit Kft. általános iskolákban működtetett
          projektje és minden ehhez kapcsolódó folyamat gyűjtő megnevezése.
        </p>
        <p>
          Tízórai kártya: az Uzsi4Kids Nonprofit Kft. által kibocsátott, a
          kártyaszámot és jelszót feltüntető, előre feltöltött Tízórai kártya,
          amely az iskolai automatából történő áruk kiadásához szolgál.
        </p>
        <p>
          Az iskola azt az iskolai létesítményt jelenti, ahová a Kártyabirtokos
          jár, és ahol az adott automata található.
        </p>
        <p>
          2. Kártyabirtokos az a személy, aki részére a Tízórai kártyát
          kiállították. A tranzakció alatt a Tízórai kártyával adott automatából
          való termékek kivétele értendő.
        </p>
        <p>
          3. A közzététel információ vagy ismertető rendelkezésre bocsátását
          vagy hozzáférhetővé tételét jelenti az alábbi módok bármelyikén:
        </p>
        <ul className="ml-20">
          <li>
            közzététel útján az Uzsi4Kids Nonprofit Kft. honlapján:
            www.tizorai.com;
          </li>
          <li>az iskolák területén történő közzététel;</li>
          <li>
            a szerződő félnek az Uzsi4Kids Nonprofit Kft.
            postai/kapcsolattartási címére küldött írásbeli értesítéssel;
          </li>
          <li>
            elektronikus úton, a megfelelő e-mail-címre történő elküldéssel;
          </li>
        </ul>
        <p>
          4. Az általános szerződési feltételek az Uzsi4Kids Nonprofit Kft.
          Általános Szerződési Feltételeit jelentik.
        </p>
        <p>
          5. Az árlista a Kártya kibocsátásához és használatához kapcsolódó
          Tízórai szolgáltatások nyújtásának díjlistáját jelenti.
        </p>
      </div>
    </div>
  );
};

const ASZF2 = () => {
  return (
    <div id="2">
      <h1>II. Általános feltételek</h1>
      <div className="aszf__section">
        <p>
          1. Az Uzsi4Kids Nonprofit Kft. az általánosan kötelező érvényű
          jogszabályoknak és az Általános Szerződési Feltételeknek megfelelően
          bocsátja ki a Tízórai kártyákat.
        </p>
        <p>
          2. A Tízórai kártyát az iskolában arra jogosult személy osztja ki.
        </p>
        <p>
          3. A Tízórai kártya kiosztását követően a Kártyabirtokos számára az
          iskolai jogviszonyának végéig használati jogot biztosít az Uzsi4Kids
          Nonprofit Kft.
        </p>
        <p>
          4. A Kártyabirtokos egyedi azonosítója a Kártya száma és jelszava.
        </p>
      </div>
    </div>
  );
};

const ASZF3 = () => {
  return (
    <div id="3">
      <h1>III. A Kártya kiállítása</h1>
      <div className="aszf__section">
        <p>
          1. A szerződés a Kártyabirtokossal a Tízórai kártya átvételekor jön
          létre, az első feltöltés után, aktivációkor. A Tízórai kártya
          feltöltéséhez az útmutatónak megfelelően kell eljárni és fel kell
          tüntetni a Közlemény rovatban a Tízórai kártya számát. Amennyiben ezen
          útmutató nem kerül betartásra és a Közlemény hibásan, vagy nem kerül
          feltüntetésre, úgy a Tízórai kártya nem aktiválódik. Ez esetben
          kérjük, forduljon ügyfélszolgálatunkhoz az info@tizorai.com e-mail
          címen, hogy a fizetést be tudjuk azonosítani és az aktiválás
          megtörténhessen.
        </p>
        <p>2. A kiosztott Tízórai kártya a Kártyabirtokos tulajdona.</p>
        <p>
          3. A szerződés határozott időre jön létre, tárgyév november 30-ig,
          tehát alapvető esetben a Tízórai kártya addig érvényes. Amennyiben a
          Kártyabirtokos szeretné meghosszabbítani Tízórai kártyáját, csupán
          további egyenlegfeltöltésre van hozzá szüksége.
        </p>
        <p>
          4. Az automatikus meghosszabbítás iránti igény esetén legalább 200
          forintnyi kreditet kell feltölteni a kártyára. A feltöltésnek adott év
          július 1 és adott év november 30 között kell megtörténnie ahhoz, hogy
          a meghosszabbítás megvalósuljon, ellenkező esetben a Szerződés
          megszűnik adott év november 30-a után.
        </p>
      </div>
    </div>
  );
};
const ASZF4 = () => {
  return (
    <div id="4">
      <h1>IV. A Tízórai kártya használata</h1>
      <div className="aszf__section">
        <p>
          1. A Tízórai kártya kizárólag az adott iskolai automatában történő
          árukiadásra és az értékesítés tárgyát képező áruk kifizetésére szha a
          Kártyabirtokos vagy törvényes képviselője ezt kéri. A tranzakció akkor
          engedélyezett, ha ahhoz a Kártyabirtokos hozzájárult. A Kártyabirtokos
          Tranzakcióhoz való hozzájárulása a Tízórai kártya behelyezését jelenti
          az iskolai automata kártyaolvasójába, továbbá az adott termék
          kiválasztását és kivételét az iskolai automatából.
        </p>
        <p>
          3. Az Uzsi4Kids Nonprofit Kft. jogosult a Tízórai kártyát bármikor és
          minden következmény nélkül letiltani:
        </p>
        <ul className="ml-20">
          <li>
            A Tízórai kártya biztonsága miatt, különösen a Kártya elvesztése,
            ellopása, nem rendeltetésszerű használata vagy jogosulatlan
            használata esetén;
          </li>
          <li>ha a Kártyabirtokos vagy törvényes képviselője ezt kéri.</li>
        </ul>
      </div>
    </div>
  );
};

const ASZF5 = () => {
  return (
    <div id="5">
      <h1>V. Szállítás, reklamáció</h1>
      <div className="aszf__section">
        <p>
          1. Az Uzsi4Kids Nonprofit Kft. és a Kártyabirtokos között váltott
          üzeneteket, dokumentumokat és utasításokat az Iskola erre
          felhatalmazott alkalmazottja személyesen kézbesíti, vagy más
          egyeztetett biztonságos módon juttatja el a Felek részére.
        </p>
        <p>
          2. Amennyiben a Kártyabirtokos a megvásárolt tétellel nem ért egyet, e
          tény tudomására jutását követően késedelem nélkül, de legkésőbb a
          Kártyaszámláról történő terheléstől számított 2 hónapon belül köteles
          írásban benyújtani esetleges igényét. Az igénylést írásban a
          feltüntetett e-mail címre kell megküldeni. Az Uzsi4Kids Nonprofit Kft.
          legkésőbb az írásbeli panasz kézbesítésétől számított 30 napon belül
          köteles dönteni a panaszról.
        </p>
        <p>3. Indokolt reklamáció esetén az igényelt tételt visszatérítjük.</p>
      </div>
    </div>
  );
};

const ASZF6 = () => {
  return (
    <div id="6">
      <h1>VI. Biztonság és védelem</h1>
      <div className="aszf__section">
        <p>1. A Kártyabirtokos köteles:</p>
        <ul className="ml-20">
          <li>elkerülni a Tízórai kártya elvesztését, ellopástól megvédését</li>
          <li>megakadályozni a Tízórai kártyával való visszaélést,</li>
        </ul>
        <p>
          2. A Tízórai kártya elvesztése, eltulajdonítása, visszaélés,
          jogosulatlan használat vagy a Tízórai kártya megsérülése esetén a
          Kártyabirtokos köteles a Tízórai kártyát saját maga zárolni a
          mobilapplikációjában, vagy erről haladéktalanul értesíteni az
          Uzsi4Kids Nonprofit Kft.-t az info@tizorai.com e-mail címen, vagy az
          Iskola erre felhatalmazott munkatársán keresztül.
        </p>
        <p>
          3. A Kártya letiltását követően az Iskola erre felhatalmazott
          munkatársa új Tízórai kártyát oszt ki. Az eredeti kártyán szereplő
          egyenleg új kártyára történő jóváírása csak az eredeti kártya
          ugyanazon Kártyabirtokos tulajdonának ellenőrzése után történik meg.
          Az átvezetési kérelem a feltüntetett e-mail címre kell, hogy
          megküldésre kerüljön.
        </p>
      </div>
    </div>
  );
};
const ASZF7 = () => {
  return (
    <div id="7">
      <h1>VII. A feltételek és az árlista változásai</h1>
      <div className="aszf__section">
        <p>
          Az Uzsi4Kids Nonprofit Kft. bármikor javasolhatja a jelen Feltételek
          és/vagy az Árlista módosítását, törlését vagy kiegészítését közzététel
          útján.
        </p>
      </div>
    </div>
  );
};
const ASZF8 = () => {
  return (
    <div id="8">
      <h1>VIII.Tájékoztatás a személyes adatokról a GDPR szerint</h1>
      <div className="aszf__section">
        <p>
          Az Uzsi4Kids Nonprofit Kft. a személyes adatokat az Európai Parlament
          és az Európai Tanács 2016. április 27-i (EU) 2016/679 rendelete (GDPR
          rendelet) szerint kezeli. A GDPR rendelet a személyes adatok
          védelmével összefüggésben biztosítja a személyes adatokhoz való
          hozzáférés kérésének, azok helyesbítésének vagy törlésének, illetve az
          adatkezelés korlátozásának kérelmét, valamint az adatkezelés elleni
          tiltakozás jogát, valamint az adathordozhatósághoz való jogot.
        </p>
      </div>
    </div>
  );
};

const ASZF9 = () => {
  return (
    <div id="9">
      <h1>IX. A szerződéses jogviszony megszűnése és záró rendelkezések</h1>
      <div className="aszf__section">
        <p>
          1. A szerződéses jogviszony a Kártya lejáratával megszűnik. A lejárat
          előtt a Kártyabirtokos köteles a Tízórai kártyán szereplő egyenlegét
          felhasználni, mert a szerződéses jogviszony megszűnésekor a fennmaradó
          pénzösszeg elvész, és az Uzsi4Kids Nonprofit Kft. az el nem költött
          pénzeszközökért kártérítést nem fizet.
        </p>
        <p>
          2. A szerződő felek közötti minden kötelezettségre a Polgári
          Törvénykönyv és az egyéb hatályos jogszabályok az irányadók.
        </p>
        <p>
          3. Jelen Általános Szerződési Feltételek 2023.03.01-jén lépnek
          hatályba. <br />
          Új kártya aktiválása az első feltöltés után a folyó tanévben: <br />
          Az első feltöltés jelentése: <br />
        </p>
        <ul className="ml-20">
          <li>
            készpénz nélküli Tízórai-kártyafeltöltés (aktiváláskor 200 forint)
            egyenleg átvezetése előző Tízórai kártyáról (új kártya aktiválásakor
            200 forint)
          </li>
        </ul>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <section className="slider__area pt-160 mb-50 z-index-1 aszf ">
      <div className="container">
        <div className="row">
          <h1 className="text-center aszf__h1">
            Általános szerződési feltételek
          </h1>
          <div className="col-3 d-none d-sm-block ">
            <div className="sticky-top aszf__nav">
              <div class="nav flex-column">
                <HashLink to="#1">I. Az alapfogalmak meghatározása</HashLink>
                <HashLink to="#2">II. Általános feltételek</HashLink>
                <HashLink to="#3">III. A Kártya kiállítása</HashLink>
                <HashLink to="#4">IV. A Tízórai kártya használata</HashLink>
                <HashLink to="#5">V. Szállítás, reklamáció</HashLink>
                <HashLink to="#6">VI. Biztonság és védelem</HashLink>
                <HashLink to="#7">
                  VII. A feltételek és az árlista változásai
                </HashLink>
                <HashLink to="#8">
                  VIII.Tájékoztatás a személyes adatokról a GDPR szerint
                </HashLink>
                <HashLink to="#9">
                  IX. A szerződéses jogviszony megszűnése és záró rendelkezések
                </HashLink>
              </div>
            </div>
          </div>
          <div className="col">
            <ASZF1 />
            <ASZF2 />
            <ASZF3 />
            <ASZF4 />
            <ASZF5 />
            <ASZF6 />
            <ASZF7 />
            <ASZF8 />
            <ASZF9 />
          </div>
        </div>
      </div>
    </section>
  );
};

const ASZF = () => {
  return (
    <>
      <Helmet>
        <title>ASZF</title>
        <meta
          name="description"
          content="Általános szerződési feltételek a Tízórai automatával kapcsolatban"
        />
        <link rel="canonical" href="/aszf" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />
        <Content />
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default ASZF;

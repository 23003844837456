import React from "react";
import { Helmet } from "react-helmet-async";
import { FooterSix, HeaderSix, Wrapper } from "src/layout/index";
import SEO from "src/components/seo";

import HomeBanner4 from "src/components/hero-banners/home-banner4";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Kapcsolat</title>
        <meta
          name="description"
          content="Lépjen velünk kapcsolatba szülő, iskola vagy egy vállalat nevében."
        />
        <link rel="canonical" href="/kapcsolat" />
      </Helmet>
      <Wrapper>
        <SEO />
        <HeaderSix />

        <div className="mb-180"></div>
        <HomeBanner4 />
        <div className="mb-120"></div>
        <FooterSix />
      </Wrapper>
    </>
  );
};

export default ContactPage;

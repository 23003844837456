import React from "react";

// internal
import shape1 from "src/styles/img//banner3a.svg";
import shape2 from "src/styles/img//banner3b.svg";
import shape3 from "src/styles/img//banner3c.svg";

const Banner3 = ({ src, title1, title2, paragraph }) => {
  return (
    <div className="text-center hero3__banner">
      <img className="" src={src} alt={title1} />
      <h3 className="hero3__banner__title mt-15">
        {title1} <br /> {title2}
      </h3>
      <p className="hero3__banner__paragraph pl-40 pr-40">{paragraph}</p>
    </div>
  );
};

const HomeBanner3 = () => {
  return (
    <>
      <section className="slider__area pt-20 z-index-1 p-relative fix">
        <div className="container">
          <div className="text-center">
            <h2>Tízórai</h2>
            <p>Mi lenne a projekt lényege?</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 mt-20">
              <Banner3
                src={shape1}
                title1="Egészséges"
                title2="termékek"
                paragraph="Gyümölcs- és zöldségkészítmények egészséges összetevőkkel, tejtermékek, vizek és további falatok a diákok számára, hogy az egész napot tele energiával töltsék."
              ></Banner3>
            </div>
            <div className="col-lg-4 col-12 mt-2">
              <Banner3
                src={shape2}
                title1="Regionális"
                title2="gyártók"
                paragraph="Célunk az egészséges termékek népszerűsítése és fejlesztése a magyarországi vállalkozásokkal karöltve, hogy a gyermekek az igényeiknek megfelelő Tízóraihoz juthassanak."
              ></Banner3>
            </div>
            <div className="col-lg-4 col-12 mt-2">
              <Banner3
                src={shape3}
                title1="Készpénzmentes"
                title2="fizetés"
                paragraph="Saját Tízórai kártyákkal kényelmes és biztonságos módon juthat gyermeke nassolnivalóhoz és üdítőhöz a nap bármely szakában."
              ></Banner3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner3;
